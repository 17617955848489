<template>
  <b-nav-item-dropdown toggle-class="pt-1 pb-0" menu-class="text-nowrap text-left text-sm-right scrollable-menu" right lazy @shown="focus">
    <template #button-content>
      <Icon type="l" isNav :isButton="false" /> {{ $t('lng.t') }}
    </template>
    <b-dd-item-button
        v-for="(lang, id) in langs"
        :key="`lng-${lang}`" :ref="`lng-${id}`" :value="lang" @click="set(id)" :active="settLang===id">
      {{ $t('lng.' + lang) }}
    </b-dd-item-button>
  </b-nav-item-dropdown>
</template>

<script>
import Icon from '@/components/Icon';
import langSet from '@/components/data/Languages';
import { switchLangAsync } from '@/plugins/i18n';

export default {
  data() {
    return {
      langs: langSet
    }
  },
  computed: {
    settLang() {
      return this.$store.state.settings.languageId;
    },
  },
  methods: {
    set(id) {
      switchLangAsync(this.settLang, this.langs[id]).then(() => {
        if (this.settLang !== id) {
          this.$store.dispatch('setLanguage', id);
        }
      });
    },
    focus() {
      this.$refs['lng-'+this.settLang][0].$el.childNodes[0].focus()
    },
  },
  components: {
    Icon,
  },
}
</script>
